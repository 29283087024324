import React from "react"
import styled from "styled-components"
import theme from "./layout/theme"

const Container = styled.div`
    background-color: ${theme.primaryFontColor};
    padding: 1px 15px 1px 15px;
`

const Text = styled.p`
    font-family: ${theme.fontSansSerif};
    color: ${theme.lightColor};
    font-weight: 200;
    font-size: 1em;
    line-height: 1.3;
`

const Information = styled.div`
    text-align: left;
    margin: 5px 0 0 0;   
`

const InfoBox = ({ infoStrings }) => {
    return (
        <Container>
            <Text>
                { infoStrings.length > 0 && infoStrings.map(infoString => (
                    <Information>
                        { infoString }
                    </Information>
                ))}
            </Text>
        </Container>
    );
};


export default InfoBox