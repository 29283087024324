import React from "react"
import styled from "styled-components"
import theme from "../components/layout/theme"

const Content = styled.div`
  font-family: ${theme.fontSansSerif};
  font-size: 1em;
  color: ${theme.primaryFontColor};

  p {
    margin-top: 0px;
  }

  h1 {
    font-family: ${theme.fontSerif};
    font-weight: 400;
    font-size: 1.7em;
    color: ${theme.primaryFontColor};

    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  h2 {
    font-family: ${theme.fontSerif};
    font-weight: 400;
    font-size: 1.3em;
    color: ${theme.primaryFontColor};

    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 15px;
  }

  h3 {
    font-family: ${theme.fontSerif};
    font-weight: 400;
    font-size: 1.1em;
    color: ${theme.primaryFontColor};

    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
`

const MarkdownContent = ({ htmlData }) => {
  return (
    <Content dangerouslySetInnerHTML = {{ __html: htmlData }} />
  );
};

export default MarkdownContent