import React from "react"
import styled from "styled-components"
import theme from "./layout/theme"

const Container = styled.div`
    background-color: ${theme.accentColor};
    padding: 15px;
`

const Title = styled.h2`
    font-family: ${theme.fontSerif};
    font-weight: 100;
    font-size: 1.3em;
    color: ${theme.primaryFontColor};
`

const Text = styled.p`
    font-family: ${theme.fontSerif};
    color: ${theme.primaryFontColor};
    font-weight: 200;
    font-size: 1em;
    line-height: 1.2;

    a:link, a:visited {
        color: ${theme.lightLinkColor};
        text-decoration: none;
    }

    a:hover {
        color: ${theme.primaryFontColor};
    } 
`

const Ingredient = styled.p`
    margin: 5px 0 0 0;   
    display: flex;
    flex-direction: row;
`

const IngredientQuantity = styled.div`
    text-align: right;
    padding-right: 15px;
    width: 30%;
`

const IngredientText = styled.div`
    text-align: left;
    width: 70%;
`

function renderIngredientText(ingredient) {
    var ingredientText = ingredient.name
    if (ingredient.manufacturer) {
        ingredientText = ingredientText + " (" + ingredient.manufacturer + ")"
    }
    if (ingredient.nameNote) {
        ingredientText = ingredientText + " - " + ingredient.nameNote
    }
    if (ingredient.altQuantity && ingredient.altUnit) {
        ingredientText = ingredientText + " (" + ingredient.altQuantity + ingredient.altUnit + ")"
    }
    if (ingredient.affiliateLink) {
        ingredientText = ingredientText + " *"
    }
    if (ingredient.link) {
        if (ingredient.link.startsWith("/")) { 
            return <a href={ingredient.link}>{ingredientText}</a>
        } else {
            return <a href={ingredient.link} target='_blank' rel='noopener noreferrer nofollow'>{ingredientText}</a>
        }
    }
    return ingredientText
}

// TODO show title
const IngredientsBox = ({ ingredientCategories }) => {
    return (
        <Container>
                { ingredientCategories.length > 0 && ingredientCategories.map(ingredientCategory => (
                    <Text>
                        { ingredientCategory.showTitle && 
                            <Title>{ingredientCategory.title}</Title> }
                            
                        { ingredientCategory.ingredients.length > 0 && ingredientCategory.ingredients.map(ingredient => (
                        
                            <Ingredient>
                                <IngredientQuantity>{ingredient.quantity} {ingredient.unit}</IngredientQuantity>
                                <IngredientText>{renderIngredientText(ingredient)}</IngredientText>
                            </Ingredient>

                        ))
                        }
                    </Text>
                    ))
                }
        </Container>
    );
};


export default IngredientsBox