import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import DateUtils from "../components/DateUtils"
import BlogPostPreviewImage from "../components/BlogPostPreviewImage"
import Badges from "../components/Badges"
import IngredientsBox from "../components/IngredientsBox"
import MarkdownContent from "./MarkdownContent"
import InfoBox from "../components/InfoBox"
import Layout from "../components/layout/layout"
import TitleHeader from "../components/layout/TitleHeader"
import { ContentContainer, Title, DateLine, BadgeWrapper, ImageContainer } from "../components/layout/ContentLayoutComponents"
import RecipeContent from "../components/RecipeContent"

const MarkdownContentContainer = styled.div`
  margin: 25px 0px 0px 0px;

  @media (min-width: 1250px) {
    margin: 25px 10% 0px 10%;
  }

  @media (min-width: 1500px) {
    margin: 25px 15% 0px 15%;
  }
`

function generateInfoStringArray(jsonData) {
  var infoStringsArray = [];
  if (jsonData.servings && jsonData.servings.description && jsonData.servings.portions) {
    const servingsString = jsonData.servings.description + ": " + jsonData.servings.portions;
    infoStringsArray.push(servingsString);
  }
  if (jsonData.difficulty) {
    const difficultyString = "Schwierigkeitsgrad: " + jsonData.difficulty;
    infoStringsArray.push(difficultyString);
  }
  return infoStringsArray;
}

export default ({ data }) => {
  const post = data.markdown;
  const ingredients = data.json.ingredients;
  const ingredientSpices = data.json.ingredientSpices;
  const title = post.frontmatter.title
  return (
    <Layout>
        <SEO title={title} />
        <Helmet title={title} />

        <ContentContainer>
          <TitleHeader title="Rezepte"/>

          { post.frontmatter.titleImageName && 
          <ImageContainer>
            <BlogPostPreviewImage imageName={post.frontmatter.titleImageName}/>
          </ImageContainer>}

          <BadgeWrapper>
            <Badges 
              categoryName={post.frontmatter.category}
              tagNames={post.frontmatter.tags} />
          </BadgeWrapper>
          
          <Title>{title}</Title>

          <DateLine>
            { DateUtils.formatDate(post.frontmatter.date) }
          </DateLine>

          <MarkdownContentContainer>
            <MarkdownContent 
                htmlData={post.html} /> 
          </MarkdownContentContainer>
          <RecipeContent jsonData={data.json} />
          
        </ContentContainer>
    </Layout>
  );
};

export const query = graphql`
query RecipeQuery($slug: String!, $recipeName: String!) {
  markdown: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      author
      date
      titleImageName
      category
      tags
    }
  }
  json: json(recipeName: {eq: $recipeName}) {
    servings {
      description
      portions
    }
    difficulty
    ingredientCategories {
      title
      showTitle
      ingredients {
        altQuantity
        altUnit
        link
        affiliateLink
        manufacturer
        name
        nameNote
        quantity
        unit
      }
    }
    instructionSteps {
      showTitle
      steps
      title
    }
  }
}
`;
