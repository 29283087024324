import React from "react"
import styled from "styled-components"

import theme from "./layout/theme"
import TitleHeader from "./layout/TitleHeader"
import IngredientsBox from "./IngredientsBox"
import InfoBox from "./InfoBox"

const Container = styled.div`
`

const ContentGrid = styled.div`
  margin-top: 25px;

  @media (min-width: 750px) {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 2fr; 
    margin-top: 45px;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`

const LeftContentSpace = styled.div`
  height: 25px;
`

const InstructionContent = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${theme.fontSansSerif};
  font-size: 1em;
  color: ${theme.primaryFontColor};
`

const InstructionStepTitle = styled.h3`
`

const SeparatorLine = styled.div`
    width: 100%;
    border-bottom: 1px solid ${theme.primaryFontColor}; 
    margin-top: 25px;
    margin-bottom: 50px;
`

function generateInfoStringArray(jsonData) {
    var infoStringsArray = [];
    if (jsonData.servings && jsonData.servings.description && jsonData.servings.portions) {
      const servingsString = jsonData.servings.description + ": " + jsonData.servings.portions;
      infoStringsArray.push(servingsString);
    }
    if (jsonData.difficulty) {
      const difficultyString = "Schwierigkeitsgrad: " + jsonData.difficulty;
      infoStringsArray.push(difficultyString);
    }
    return infoStringsArray;
}

const RecipeContent = ({ jsonData }) => {
    const instructionSteps = jsonData.instructionSteps
    return (
        <Container>
            <TitleHeader title="Rezept" />

            <ContentGrid>
                <LeftContent>
                    <InfoBox infoStrings={generateInfoStringArray(jsonData)} />
                    <LeftContentSpace/>
                    <IngredientsBox 
                        ingredientCategories={jsonData.ingredientCategories} />
                    <LeftContentSpace/>
                </LeftContent>
                
                <InstructionContent>
                {
                    instructionSteps.length > 0 && instructionSteps.map(instructionStep => (
                        <div>
                            {instructionStep.showTitle && instructionStep.title &&
                                <InstructionStepTitle>{instructionStep.title}</InstructionStepTitle>
                            }
                            <ul>
                                {
                                    instructionStep.steps.length > 0 && instructionStep.steps.map(txt => 
                                            <li>{txt}</li>
                                    )
                                }
                            </ul>
                        </div>
                    ))
                }
                </InstructionContent>

            </ContentGrid>

            <SeparatorLine />
        </Container>
    );
};


export default RecipeContent